import React, { memo } from 'react';
import { Row, Statistic, Typography } from 'antd';
import {
  Handle,
  Position,
} from '@xyflow/react';
 
 
const DisengagedNode = ({ data }) => {
  return (
    <div>
      <Row justify="center">
        <Statistic title={data.label} value={278822} valueStyle={{fontSize: 16, color: '#001246'}}/>
        <Typography style={{color: '#001246'}}>incl. 2244 subscribers</Typography>
      </Row>
      <Handle type="target" position={Position.Left} id="left-target" />
      <Handle type="target" position={Position.Bottom} id="bottom-target" />
      <Handle type="target" position={Position.Top} id="top-target" />
      <Handle type="source" position={Position.Left} id="left-source" />
      <Handle type="source" position={Position.Right} id="right" />
    </div>
  );
};
 
export default memo(DisengagedNode);