import React, { memo, useEffect } from 'react';
import { Row, Statistic, Typography } from 'antd';
import {
  Handle,
  Position,
  useUpdateNodeInternals
} from '@xyflow/react';
 
 
const RegistrationNode = ({ data }) => {

  const updateNodeInternals = useUpdateNodeInternals();

  useEffect(() => {
    updateNodeInternals('2_registration')
  }, [data.layout])

  return (
    <div >
      <Row>
        <Statistic title={data.label} value={312136} valueStyle={{fontSize: 16, color: '#001246'}}/>
      </Row>
      <Handle type="target" position={data.layout === 'extended' ? Position.Left : Position.Bottom} id="target" />
      <Handle type="source" position={Position.Top} id="top-source" style={{left: '48px'}}/>
      <Handle type="source" position={Position.Top} id="top-source-2" style={{left: '96px'}}/>
      <Handle type="source" position={Position.Right} id="source" />
    </div>
  );
};
 
export default memo(RegistrationNode);