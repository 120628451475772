
import { Layout, Menu, Col, Row, Popover, Button, Typography } from 'antd';
import {
    DashboardOutlined,
    TeamOutlined,
    NodeIndexOutlined,
    BarChartOutlined,
    QuestionOutlined,
    SlackOutlined
  } from '@ant-design/icons';
import styled from 'styled-components';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { useSecurityContext } from '../../hooks';
import jwtDecode from 'jwt-decode';
import { UserAvatar } from './UserAvatar';
import React, { useEffect, useState } from 'react';
import { glossaryURL } from '../../config';

const { Sider } = Layout;

const CustSider = styled(Sider)`
    && {
    .ant-layout-sider-trigger {
        background: transparent;
        color: #000;
        border-radius: 0 8px 8px 0;
    }
    .anticon-right {
      margin-left: -8px;
    }
  }
`;

const CustomTypography = styled(Typography)`
&& {
    :hover {
      color: #1890ff;
  }
}
`;

const CustomRow = styled(Row)`
&& {
    :hover {
      color: #1890ff;
  }
}
`;

const CustomMenu = styled(Menu)`
&& {
    .ant-menu-inline {
      background: transparent;
    }
    .ant-menu-item.ant-menu-item-selected {
      color: #003A70;
      background-color: #dde6ff;
      font-weight: 500;
      border-radius: 0 120px 120px 0;
    }
    .ant-menu-item:after {
      border-right: 0px;
    }
    .ant-menu-submenu-selected {
      color: #003A70;
    }
    .ant-menu-vertical.ant-menu-item-selected {
      color: #003A70;
    }
}

`


export default function CustomSider({ collapsed, setCollapsed}) {
    const { push } = useHistory();
    const { pathname } = useLocation();
    const { isLoggedIn, currentToken } = useSecurityContext();

    function getItem(label, key, icon, children) {
        return {
          key,
          icon,
          children,
          label,
        };
      }
    
      const items = [
        
        getItem('Insights', 'insights', <BarChartOutlined />, [
          getItem('Explore', '/insights/explore'),
          ['Consumer Subscription, Audience & Data'].includes(jwtDecode(currentToken).user_department) && getItem('Customer Journey', '/insights/journey'),
        ]),
        getItem('Audiences', '/audience', <TeamOutlined />),
        getItem('Performances', 'dashboards', <DashboardOutlined/>, [
          getItem('Article', '/dashboards/article'),
          ['CEO Office', 'CEO office', 'Consumer Subscription, Audience & Data', 'Product & Technology'].includes(jwtDecode(currentToken).user_department) && getItem('Retention', '/dashboards/retention'),
          getItem('Reports', '/dashboards/reports'),
        ]),
        ['CEO Office', 'CEO office', 'Consumer Subscription, Audience & Data'].includes(jwtDecode(currentToken).user_department) && getItem('Pipelines', 'pipelines', <NodeIndexOutlined />, [
          getItem('Dashboards', '/pipelines/dashboards'),
          getItem('Syncs', '/pipelines/syncs'),
        ]),
        
      ];

      const helpContent = (
        <Col>
          <Row>
            <Button 
                  type="link" 
                  shape="round"
                  onClick={() => window.open('https://docs.google.com/presentation/d/1SMiHk5mrZeG5WDtWNM6C6UXoSknCwJVhcCfylT7uKwM/edit#slide=id.p', "_blank")}
                  > 
              Training Slides 
            </Button>
          </Row>
          <Row>
            <Button 
                  type="link" 
                  shape="round"
                  onClick={() => window.open(glossaryURL, "_blank")}
                  > 
              Exodus Glossary 
            </Button>
          </Row>
          <Row>
            <Button 
                  type="link" 
                  shape="round"
                  onClick={() => window.open('https://docs.google.com/spreadsheets/d/1xbvR4DXeIfbTzLTEbK3Gm8xAjvrw-81hSOHSQ8r8fZ0/edit?gid=1984298132#gid=1984298132', "_blank")}
                  > 
              Tracking Plan
            </Button>
          </Row>
          <Row>
            <Button 
                  type="link" 
                  shape="round"
                  onClick={() => window.open('https://scmp.slack.com/messages/CBBBQEZPC', "_blank")}
                  icon={<SlackOutlined />}
                  > 
              Join the channel
            </Button>
          </Row>
        </Col>
      );
    
      useEffect(() => {
        if(pathname === '/insights/journey'){
          setCollapsed(true)
        }
      }, [pathname])
    
    return (
        <CustSider
            collapsible
            breakpoint="lg"
            onBreakpoint={broken => { console.log(broken); }}
            collapsed={collapsed} 
            onCollapse={(value) => setCollapsed(value)}
            // style={{backgroundColor: '#fff', height: 'fit-content', boxShadow: '1px 4px 4px 2px rgba(0, 0, 0, .02)', borderRadius: '0 8px 8px 0'}}
            style={{background: 'none', height: 'fit-content', borderRadius: '0 8px 8px 0'}}
        >
        <Col >
            {/* <div style={{borderRadius: '0 8px 8px 0'}}> */}
              <CustomMenu 
                  theme={'light'} 
                  selectedKeys={pathname.startsWith('/pipelines/sync') ? ['/pipelines/syncs'] : pathname.startsWith('/pipelines/dashboard') ? ['/pipelines/dashboards'] : [pathname]}
                  defaultOpenKeys={[pathname === '/' ? 'insights' : pathname.split('/')[1]]}
                  mode="inline" 
                  style={{
                      paddingTop: '16px',
                      borderRight: 0,
                      borderRadius: '0 8px 0 0',
                      background: 'none'
                  }}
                  items={items} 
                  onClick={(e) => {
                      if([
                        '/insights/explore', '/insights/journey', '/audience', '/dashboards/article', '/dashboards/retention', '/dashboards/reports',
                        '/pipelines/dashboards', '/pipelines/syncs'
                      ].includes(e.key)){
                          push(e.key)
                      }  
                  }}
              />
              {isLoggedIn && <Row style={{ marginLeft: 'auto', marginTop: 6}}>
              <Popover placement="right" title={'Help'} content={helpContent} trigger="click">
                <CustomRow justify='center' align='middle' style={{cursor: 'pointer', position: 'fixed', bottom: 120, left: collapsed ? 28 : 20}}>
                  <QuestionOutlined />
                  {!collapsed && <CustomTypography style={{marginLeft: 4}}>Help</CustomTypography>}
                </CustomRow>
              </Popover>
              <UserAvatar collapsed={collapsed} setCollapsed={setCollapsed}/>
            </Row>}
        </Col>
        </CustSider>
    );
}