import {
  ReactFlow,
  Background,
  Position,
  MarkerType,
  useNodesState,
  useEdgesState,
} from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import './xy-theme.css';
import { Typography } from 'antd';
import BiDirectionalEdge from './BiDirectionalEdge';
import DisengagedNode from './DisengagedNode';
import EngagedNode from './EngagedNode';
import SubscriptionNode from './SubscriptionNode';
import RegistrationNode from './RegistrationNode';
import ChurnNode from './ChurnNode';
import FirstVisitNode from './FirstVisitNode';
import LabelNode from './LabelNode';
import { useMediaQuery } from 'react-responsive';
import { useEffect, useState } from 'react';
 
const initialNodes = [
  {
    id: '1_first-visit',
    data: { label: 'First Visit', layout: 'extended' },
    position: { x: 20, y: 280 },
    positionTight: { fatX: 20, fatY: 460, bigX: 10, bigY: 460, x: 10, y: 420 },
    sourcePosition: Position.Right,
    targetPosition: Position.Right,
    type: 'firstvisit',
  },
  {
    id: '2_registration',
    data: { label: 'Registration', layout: 'extended' },
    position: { x: 290, y: 280 },
    positionTight: { fatX: 20, fatY: 280, bigX: 10, bigY: 280, x: 10, y: 260 },
    sourcePosition: Position.Right,
    targetPosition: Position.Left,
    type: 'registration',
  },
  {
    id: '3_subscription',
    data: { label: 'Subscription' },
    type: 'subscription',
    position: { x: 540, y: 280 },
    positionTight: { fatX: 350, fatY: 280, bigX: 250, bigY: 280, x: 220, y: 260 },
    sourcePosition: Position.Right,
    targetPosition: Position.Left,
  },
  {
    id: '4_engaged',
    data: { label: 'Engaged' },
    position: { x: 540, y: 80 },
    positionTight: { fatX: 350, fatY: 80, bigX: 250, bigY: 80, x: 220, y: 80 },
    type: 'engaged',
    sourcePosition: Position.Right,
    targetPosition: Position.Left,
  },
  {
    id: '5_disengaged',
    data: { label: 'Disengaged' },
    position: { x: 810, y: 80 },
    positionTight: { fatX: 652, fatY: 80, bigX: 492, bigY: 80, x: 432, y: 80 },
    type: 'disengaged',
    sourcePosition: Position.Right,
    targetPosition: Position.Left,
  },
  {
    id: '6_churn',
    data: { label: 'Churn' },
    position: { x: 980, y: 280 },
    positionTight: { fatX: 752, fatY: 460, bigX: 492, bigY: 460, x: 422, y: 420 },
    targetPosition: Position.Left,
    type: 'churn',
  },
];

   
const initialEdges = [
  { 
    id: '1-2', 
    source: '1_first-visit', 
    target: '2_registration', 
    type: 'step',
    markerEnd: {
      type: MarkerType.ArrowClosed,
      color: '#3575dd'
    },
    animated: true,
    style: {
      strokeWidth: 2,
    },
    label: "0.44%",
    labelStyle: {
      fontSize: 12,  
    },
  },
  { 
    id: '2-3', 
    source: '2_registration', 
    target: '3_subscription', 
    type: 'step',
    markerEnd: {
      type: MarkerType.ArrowClosed,
      color: '#3575dd'
    },
    targetHandle: 'left',
    sourceHandle: 'source',
    animated: true,
    style: {
      strokeWidth: 2,
    },
    label: "3.11%",
    labelStyle: {
      fontSize: 12,  
    },
  },
  { 
    id: '2-4', 
    source: '2_registration', 
    target: '4_engaged', 
    markerEnd: {
      type: MarkerType.ArrowClosed,
      color: '#3575dd'
    },
    type: 'step',
    targetHandle: 'left-target',
    animated: true,
    sourceHandle: 'top-source-2',
    style: {
      strokeWidth: 2,
      stroke: '#3F88FE',
    },
    label: '10.63%',
    labelStyle: {
      fontSize: 12,  
    },
  },
  { 
    id: '2-5', 
    source: '2_registration', 
    target: '5_disengaged', 
    markerEnd: {
      type: MarkerType.ArrowClosed,
      color: '#3575dd'
    },
    type: 'step',
    sourceHandle: 'top-source',
    targetHandle: 'top-target',
    animated: true,
    style: {
      strokeWidth: 2,
    },
    label: '89.37%',
    labelStyle: {
      fontSize: 12,  
    },
  },
  { 
    id: '3-4', 
    source: '3_subscription', 
    target: '4_engaged', 
    type: 'step',
    markerEnd: {
      type: MarkerType.ArrowClosed,
      color: '#3575dd'
    },
    animated: true,
    sourceHandle: 'top',
    style: {
      strokeWidth: 2,
    },
    label: '60.94%',
    labelStyle: {
      fontSize: 12,  
    },
  },
  { 
    id: '4-5', 
    source: '4_engaged', 
    target: '5_disengaged', 
    type: 'bidirectional', 
    markerEnd: {
      type: MarkerType.ArrowClosed,
      color: '#3575dd'
    },
    sourceHandle: 'right-source',
    targetHandle: 'left-target',
    animated: true,
    style: {
      strokeWidth: 2,
    },
  },
  { 
    id: '3-5', 
    source: '3_subscription', 
    target: '5_disengaged',  
    markerEnd: {
      type: MarkerType.ArrowClosed,
      color: '#3575dd'
    },
    type: 'step',
    targetHandle: 'bottom-target',
    animated: true,
    style: {
      strokeWidth: 2,
    },
    label: '39.05%',
    labelStyle: {
      fontSize: 12,  
    },
    labelBgStyle: {
      background: 'transaparent'
    }
  },
  { 
    id: '5-6', 
    source: '5_disengaged', 
    target: '6_churn', 
    markerEnd: {
      type: MarkerType.ArrowClosed,
      color: '#3575dd'
    },
    type: 'step',
    sourceHandle: 'right',
    animated: true,
    style: {
      strokeWidth: 2,
    },
  },
  { 
    id: '5-4', 
    source: '5_disengaged', 
    target: '4_engaged', 
    type: 'bidirectional', 
    markerEnd: {
      type: MarkerType.ArrowClosed,
      color: '#3575dd'
    },
    sourceHandle: 'left-source',
    targetHandle: 'right-target',
    animated: true,
    style: {
      strokeWidth: 2,
    },
  },
  { 
    id: '6-3', 
    source: '6_churn', 
    target: '3_subscription', 
    markerEnd: {
      type: MarkerType.ArrowClosed,
      color: '#3575dd'
    },
    label: "winback = 66",
    labelStyle: {
      fontSize: 12,  
    },
    animated: true,
    type: 'step',
    style: {
      strokeWidth: 2,
    },
  },

];

const edgeTypes = {
  bidirectional: BiDirectionalEdge,
};
 
const nodeTypes = {
  disengaged: DisengagedNode,
  engaged: EngagedNode,
  subscription: SubscriptionNode,
  registration: RegistrationNode,
  firstvisit: FirstVisitNode,
  churn: ChurnNode,
  label: LabelNode
};
 
export function CustomerJourneyGraph({ setSelectedNode, selectedNode, setDrawerOpen, drawerOpen }) {

  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);

  const isFatMonitor = useMediaQuery({
    query: '(min-width: 2200px)',
  });

  const isBigMonitor = useMediaQuery({
    query: '(min-width: 1800px)',
  });


  const onInit = (rf) => {
    setReactFlowInstance(rf);
  };

  const handleNodeClick = (_, node) => {
    if (node){
      setSelectedNode(node.data.label)
      setDrawerOpen(true);
      setNodes(nodes.map(n => {
        if(n.id === node.id){
          return {...n, selected: true}
        }
        return {...n, selected: false}
      }))
    } else {
      setSelectedNode(null)
      setDrawerOpen(false);
      setNodes(nodes.map(n => {
        return {...n, selected: false}
      }))
    }
  }

  useEffect(() => {
    if (reactFlowInstance && nodes?.length) {
      setNodes(nodes.map(n => {
          if (drawerOpen){
            return {
              ...n, 
              position: {
                x: isFatMonitor ? initialNodes.find(node => node.id === n.id).positionTight.fatX : isBigMonitor ? initialNodes.find(node => node.id === n.id).positionTight.bigX : initialNodes.find(node => node.id === n.id).positionTight.x, 
                y: isFatMonitor ? initialNodes.find(node => node.id === n.id).positionTight.fatY : isBigMonitor ? initialNodes.find(node => node.id === n.id).positionTight.bigY : initialNodes.find(node => node.id === n.id).positionTight.y,
              },
              data: {...n.data, layout: 'tight'}
            }
          } else {
            return {
              ...n, 
              position: {
                x: isFatMonitor ? 280 + n.position.x : isBigMonitor ? 70 + n.position.x : 0 + n.position.x,
                y: n.position.y
              },
              data: {...n.data, layout: 'extended'}
            }
          }
      }))
    }
  }, [drawerOpen]);

  useEffect(() => {
    if(!selectedNode){
      setNodes(nodes.map(n => {
        if(!['group', 'label'].includes(n.type)){
          return {
            ...n, 
            selected: false, 
            position: {
              x: isFatMonitor ? 280 + initialNodes.find(node => node.id === n.id).position.x : isBigMonitor ? 70 + initialNodes.find(node => node.id === n.id).position.x : 0 + initialNodes.find(node => node.id === n.id).position.x, 
              y: initialNodes.find(node => node.id === n.id).position.y
            },
            data: {...n.data, layout: 'extended'}
          }
        } else if (n.type === 'group' && n.data.label === 'Stage'){
          return {
            ...n, 
            height: drawerOpen ?  360 : 200
          }
        }
        return n
      }))
    }
  }, [selectedNode])
 
  return (
    <div style={{ maxWidth: 2400, height: (isFatMonitor || isBigMonitor) ? '72vh' : drawerOpen ? '66vh' : '56vh', paddingTop: isFatMonitor ? 72 : isBigMonitor ? 72 : 0 }}>
      <ReactFlow
        onInit={onInit}
        nodes={nodes}
        onNodesChange={onNodesChange}
        edges={edges}
        onEdgesChange={onEdgesChange}
        edgeTypes={edgeTypes}
        nodeTypes={nodeTypes}
        onNodeClick={handleNodeClick}
        zoomOnScroll={false}
        preventScrolling={false}
        panOnDrag={false}
        onPaneClick={handleNodeClick}
      >
        <Background color="#ccc" />
        {/* <Typography style={{marginLeft: '16px', marginTop: '72px', fontStyle: 'italic'}}>click on a node to discover insights ✨</Typography> */}
      </ReactFlow>
    </div>
  );
}
 
export default CustomerJourneyGraph;
