import React, { memo, useEffect } from 'react';
import { Row, Statistic, Typography } from 'antd';
import {
  Handle,
  Position,
  useUpdateNodeInternals
} from '@xyflow/react';
 
const { Title } = Typography;
 
const FirstVisitNode = ({ data }) => {

  const updateNodeInternals = useUpdateNodeInternals();

  useEffect(() => {
    updateNodeInternals('1_first-visit')
  }, [data.layout])
  
  return (
    <div >
      <Row>
        <Statistic title={data.label} value={70567362} valueStyle={{fontSize: 16, color: '#001246'}}/>
      </Row>
      <Handle type="source" position={data.layout === 'extended' ? Position.Right : Position.Top} id="source" />
    </div>
  );
};
 
export default memo(FirstVisitNode);