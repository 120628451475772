import { notification } from 'antd';
import { pretty } from 'js-object-pretty-print';

// Miscelleanous helper functions
export function metaToTypes(meta) {
  const types = {};

  Object.values(meta.cubesMap).forEach((membersByType) => {
    Object.values(membersByType).forEach((members) => {
      Object.values(members).forEach(({ name, type }) => {
        types[name] = type;
      });
    });
  });

  return types;
}

export function unCapitalize(name) {
  return `${name[0].toLowerCase()}${name.slice(1)}`;
}

export function capitalize(name) {
  return `${name[0].toUpperCase()}${name.slice(1)}`;
}

export function uniqArray(array) {
  return Array.from(new Set(array));
}

export function fetchPoll(
  url,
  timeout,
  callback,
  fetchOptions
) {
  let retries = 0;
  let canceled = false;

  function cancel() {
    canceled = true;
  }

  async function request() {
    const response = await fetch(url, fetchOptions);

    if (!canceled) {
      callback({
        response,
        cancel,
        retries,
      });

      setTimeout(request, timeout);
    }

    retries++;
  }

  request();

  return {
    cancel,
    retries,
  };
}

export function fetchWithTimeout(
  url,
  options,
  timeout
) {
  return Promise.race([
    fetch(url, options),
    new Promise((_, reject) =>
      setTimeout(() => reject(new Error('timeout')), timeout)
    ),
  ]);
}

export async function copyToClipboard(value, message = 'Copied to clipboard') {
  if (!navigator.clipboard) {
    notification.error({
      message: "Your browser doesn't support copy to clipboard",
    });
  }

  try {
    await navigator.clipboard.writeText(value);
    notification.success({
      message,
    });
  } catch (e) {
    notification.error({
      message: "Can't copy to clipboard",
      description: e,
    });
  }
}

export function formatNumber(num) {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}
export function makePercentage(num, digits){
  return [parseFloat(num * 100).toFixed(digits), '%'].join('')
}

function padTo2Digits(num) {
  return num.toString().padStart(2, '0');
}

export function prettifyObject(value) {
  return pretty(value, 2)
    .replaceAll(/([^\\]|)'/g, `\\'`)
    .replaceAll(/"/g, `'`)
    .replaceAll(/\[[\s]+\]/g, '[]');
}

export const addIncrementalKey = (data, offset=0) => {
    let key = offset;
    return data.map(d => {
      key += 1;
      return {key: key, ...d}
    });
  }
  
// For PKCE
  export const createCodeVerifier = ( size ) => {
    const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_.~';
    const charsetIndexBuffer = new Uint8Array( size );
  
    for ( let i = 0; i < size; i += 1 ) {
      charsetIndexBuffer[i] = ( Math.random() * charset.length ) | 0;
    }
  
    let randomChars = [];
    for ( let i = 0; i < charsetIndexBuffer.byteLength; i += 1 ) {
      let index = charsetIndexBuffer[i] % charset.length;
      randomChars.push( charset[index] );
    }
  
    return randomChars.join( '' );
  }

  export const createCodeChallenge = ( codeVerifier ) => {
    if ( typeof window !== 'undefined' && !!( window.crypto ) && !!( window.crypto.subtle ) ) {
      return new Promise( ( resolve, reject ) => {
        let codeVerifierCharCodes = textEncodeLite( codeVerifier );
        crypto.subtle
          .digest( 'SHA-256', codeVerifierCharCodes )
          .then(
            hashedCharCodes => resolve( urlSafe( new Uint8Array(hashedCharCodes) ) ),
            error => reject( error )
          );
      });
    }
  }
  
  const textEncodeLite = ( str ) => {
    const charCodeBuffer = new Uint8Array( str.length );
    for ( let i = 0; i < str.length; i++ ) {
     charCodeBuffer[i] = str.charCodeAt( i );
    }
    return charCodeBuffer;
  }
  
  const urlSafe = ( buffer ) => {
    const encoded = btoa(String.fromCharCode(...new Uint8Array(buffer)));
  
    return encoded.replace( /\+/g, '-' ).replace( /\//g, '_' ).replace( /=/g, '' );
  }


  export const copyTable = async (queryId, message) => {
    const elTable = document.querySelector(`#dataTable_${queryId}`);
    
    let range, sel;
    
    // Ensure that range and selection are supported by the browsers
    try {
      if (document.createRange && window.getSelection) {
    
        range = document.createRange();
        sel = window.getSelection();
        // unselect any element in the page
        sel.removeAllRanges();
      
        try {
          range.selectNodeContents(elTable);
          sel.addRange(range);
        } catch (e) {
          range.selectNode(elTable);
          sel.addRange(range);
        }
        
        document.execCommand('copy', true, sel);
        
      }
      
      sel.removeAllRanges();
      notification.success({
        message,
      });
    } catch (e) {
      notification.error({
        message: "Can't copy to clipboard",
        description: e,
      });
    }
  }

export const avg = (arr) => {
  const sum = arr.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  const average = sum / arr.length;
  return Math.round(average);
  }


export const convertEdgelistToAdjlist = function(edgelist) {
    var adjlist = {};
    var i, len, pair, u, v;
    for (i = 0, len = edgelist.length; i < len; i += 1) {
      pair = edgelist[i];
      u = pair[0];
      v = pair[1];
      if (adjlist[u]) {
        // append vertex v to edgelist of vertex u
        adjlist[u].push(v);
      } else {
        // vertex u is not in adjlist, create new adjacency list for it
        adjlist[u] = [v];
      }
      if (adjlist[v]) {
        adjlist[v].push(u);
      } else {
        adjlist[v] = [u];
      }
    }
    return adjlist;
};
  
  // Breadth First Search using adjacency list
export const bfs = function(v, adjlist, visited) {
    var q = [];
    var current_group = [];
    var i, len, adjV, nextVertex;
    q.push(v);
    visited[v] = true;
    while (q.length > 0) {
      v = q.shift();
      current_group.push(v);
      // Go through adjacency list of vertex v, and push any unvisited
      // vertex onto the queue.
      adjV = adjlist[v];
      for (i = 0, len = adjV.length; i < len; i += 1) {
        nextVertex = adjV[i];
        if (!visited[nextVertex]) {
          q.push(nextVertex);
          visited[nextVertex] = true;
        }
      }
    }
    return current_group;
};


export function findSecondLargest(arr) {
  let largest = arr[0];
  let secondLargest = -Infinity;
for (let i = 1; i < arr.length; i++) {
    if (arr[i] > largest) {
      secondLargest = largest;
      largest = arr[i];
    } else if (arr[i] < largest && arr[i] > secondLargest) {
      secondLargest = arr[i];
    }
  }
  return secondLargest;
}


export const isValidUrl = (urlString) => {
  try { 
    return Boolean(new URL(urlString)); 
  }
  catch(e){ 
    return false; 
  }
}


export const swapCubes = (query, prefix, newPrefix) => {
  const { dimensions = [], measures = [], timeDimensions = [], segments = [], filters = [], order = [] } = query;

  const replacePrefix = (str, prefix, newPrefix) => {
    const splitStr = str.split('.');
    if (splitStr[0] === prefix) {
      splitStr[0] = newPrefix;
      return splitStr.join('.');
    }
    return str;
  };

  const replaceTimeDimensionPrefix = (timeDimension) => {
    const { dimension, ...rest } = timeDimension;
    return { dimension: replacePrefix(dimension, prefix, newPrefix), ...rest };
  };
  const replaceFilterPrefix = (filter) => {
      const { member, ...rest } = filter;
      return { member: replacePrefix(member, prefix, newPrefix), ...rest };
  };
  
  return {
    ...query,
    dimensions: dimensions.map((dimension) => replacePrefix(dimension, prefix, newPrefix)),
    measures: measures.map((measure) => replacePrefix(measure, prefix, newPrefix)),
    timeDimensions: timeDimensions.map(replaceTimeDimensionPrefix),
    segments: segments.map((segment) => replacePrefix(segment, prefix, newPrefix)),
    filters: filters.map(replaceFilterPrefix),
    order: { [replacePrefix(Object.keys(order)[0])]: Object.values(order)[0]},
  };  
}

export const playgroundActionUpdateMethods = (updateMethods, memberName) =>
Object.keys(updateMethods)
  .map((method) => ({
    [method]: (member, values, ...rest) => {
      return updateMethods[method].apply(null, [member, values, ...rest]);
    },
  }))
  .reduce((a, b) => ({ ...a, ...b }), {});