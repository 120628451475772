import styled from 'styled-components';
import { Button } from 'antd';

const StyledButton = styled(Button)`
    display: inline-flex;
    place-items: center;
    color: var(--text-color);
    border-color: #dde6ff;
    background-color: #dde6ff;
    &:disabled {
        border-color: var(--disabled-bg);
        background-color: var(--disabled-bg);
        &:hover,
        &:active,
        &:focus {
            color: var(--disabled-color);
            border-color: var(--disabled-color);
        }
    }
    &:hover {
        background-color: #dde6ff;
        border-color: #003A70;
        color: #003A70;
    }
`;

export { StyledButton as ExportButton };