import React, { Fragment, useEffect, useState } from 'react';
import { playgroundContext, buildApiUrl, filterMembers, backendApiUrl } from '../config';
import { useSecurityContext } from '../hooks';
import { DatePicker, Table, Tabs, Skeleton, Descriptions, Typography, Drawer, Collapse, notification, Avatar, Tooltip, Col, Row } from 'antd';
import { PlaySquareOutlined } from '@ant-design/icons';
import { Container, Alert, Button, Section } from '../atoms';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { QueryBuilder } from '@cubejs-client/react';
import { CustomerJourneyGraph } from '../components/CustomerJourney/CustomerJourneyGraph';
import MemberGroup from '../components/QueryBuilder/MemberGroup';
import SectionHeader from '../components/SectionHeader';
import { useCubeQuery } from '@cubejs-client/react';
import { useFilterValuesContext, FilterValuesContext } from '../components/FilterValuesContext';
import { QueryContext } from '../components/QueryContext';
import { useHistory } from 'react-router';
import moment from 'moment';
import jwtDecode from 'jwt-decode';
import TimeGroup from '../components/QueryBuilder/TimeGroup';
import FilterGroup from '../components/QueryBuilder/FilterGroup';
import {playgroundActionUpdateMethods} from '../utils';
import { ReactFlowProvider } from '@xyflow/react';

const { Title } = Typography;
const { RangePicker } = DatePicker;
const { Panel } = Collapse;

const Link = styled.a`
&& {
    &:hover {
        opacity: 0.8;
        textDecoration: 'underline';
      }
}
`;

export function CustomerJourneyPage() {
  
  const [selectedNode, setSelectedNode] = useState(null);
  const [userAudiences, setUserAudiences] = useState([]);
  const [metaExtended, setMetaExtended] = useState([]);
  const [snapshotDate, setSnapshotDate] = useState(moment().hours() >= 14 ? moment().subtract(1, 'days') : moment().subtract(2, 'days'));
  const snapshotDate6monthsPrior = (moment().hours() >= 14 ? moment().subtract(1, 'days') : moment().subtract(2, 'days')).subtract(6, 'months');
  const { basePath, baseUrl } = playgroundContext;
  const apiUrl = buildApiUrl(baseUrl, basePath);
  const { decodeCheckExpiredToken, getUserDepartment, getUserName, currentToken } = useSecurityContext();
  const department = getUserDepartment();
  const userName = getUserName();
  const { filterValues, fetchCubeJsPreAgg } = useFilterValuesContext();
  const location = useLocation();
  const { push } = useHistory();
  const GASchema = 'GA4';
  const [queryToRun, setQueryToRun] = useState([]);
  const renderProps = useCubeQuery(queryToRun, {skip: queryToRun.length === 0});
  const timeDimensions = [{dimension: 'Events.date', dateRange: 'Last 30 days'}, {dimension: 'RegisteredUsers.registration_date', dateRange: 'Last 6 months'}]
  const params = new URLSearchParams(location.search);
  let query = {};
  try{ 
    query = JSON.parse(params.get('query'));
  } catch(e) { 
    console.log('Bad query passed!')
  }
  // filter selected
  const [firstVisitDateRange, setFirstVisitDateRange] = useState([snapshotDate6monthsPrior, snapshotDate]);
  const [registrationDateRange, setRegistrationDateRange] = useState([snapshotDate6monthsPrior, snapshotDate]);
  const [subscriptionStartDateRange, setSubscriptionStartDateRange] = useState([snapshotDate6monthsPrior, snapshotDate]);
  const [engagementDateRange, setEngagementDateRange] = useState([snapshotDate6monthsPrior, snapshotDate]);
  const [countrySelected, setCountrySelected] = useState([]);
  const [platformSelected, setPlatformSelected] = useState([]);
  const [audienceSelected, setAudienceSelected] = useState(null);
  const [subscriptionProductSelected, setSubscriptionProductSelected] = useState([]);
  const [subscriptionPlanSelected, setSubscriptionPlanSelected] = useState([]);
  const [autoRenewStatusSelected, setAutoRenewStatusSelected] = useState(null);
  const [promoCodeSelected, setPromoCodeSelected] = useState([]);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [openStageFilters, setOpenStageFilters] = useState([]);
  const [openEngagementFilters, setOpenEngagementFilters] = useState([]);

  const onClose = () => {
    setDrawerOpen(false);
    setSelectedNode(null);
  };

  function setQueryParam({ query }) {
    if (!('timeDimensions' in query)) {
        query = {timeDimensions: timeDimensions};
    }
        push({ search: `?query=${encodeURIComponent(JSON.stringify(query))}` });
  }

  const getAudiencesFromDb = () => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Authorization': currentToken },
    };
    fetch(`${backendApiUrl}/audiences?sub=${jwtDecode(currentToken).sub}`, requestOptions)
        .then(response => response.json())
        .then(data => {
            setUserAudiences(data.map(d => ({value: d.name, label: d.name})))
        });
  }

  const disabledDate = (current) => {
    if (!engagementDateRange) {
        return false;
      }
    const tooLate = engagementDateRange[0] && current.diff(engagementDateRange[0], 'days') > 365;
    const tooEarly = engagementDateRange[1] && engagementDateRange[1].diff(current, 'days') > 365;
    return (!!tooEarly || !!tooLate);
  }

  const handleRunButtonClick = ({query}) => {
    // const multiQuery = query.segments.map(seg => {
    //     const cohortSeg = seg.split('.')[1];
    //     const timeGrain = capitalize(query.timeDimensions[0].granularity);
    //     const retentionCube = `Retention${timeGrain}__${cohortSeg}`
    //     return {
    //         timeDimensions: [{...query.timeDimensions[0], dimension: `${retentionCube}.date`}], 
    //         measures: [`${retentionCube}.percentage_of_active`, `${retentionCube}.total_count`, `${retentionCube}.total_active_count`],
    //         limit: limit
    //     }
    // })
    // setQueryToRun(multiQuery)
    // setLimit(limit === 1000 ? 1001 : 1000)
}


  useEffect(() => {
    getAudiencesFromDb()
    // prepare dropdowns
    fetchCubeJsPreAgg(apiUrl, currentToken, 'Events.country')
    fetchCubeJsPreAgg(apiUrl, currentToken, 'Subscriptions.subs_product')
    fetchCubeJsPreAgg(apiUrl, currentToken, 'Subscriptions.subscription_plan')
    fetchCubeJsPreAgg(apiUrl, currentToken, 'Subscriptions.auto_renew_status')
    fetchCubeJsPreAgg(apiUrl, currentToken, 'Subscriptions.promo_code')
    fetchCubeJsPreAgg(apiUrl, currentToken, 'Events.platform')
    fetchCubeJsPreAgg(apiUrl, currentToken, 'UsersAttributes.audience_segment_from_cluster')
  }, [])

  useEffect(() => {
    if(selectedNode){
      setOpenEngagementFilters([])
      setOpenStageFilters([])
    }
  }, [selectedNode])


  return (
    <ReactFlowProvider>
    <QueryContext apiUrl={apiUrl} metaExtended={metaExtended} department={department}>
        <QueryBuilder
            defaultQuery={query || {timeDimensions:  timeDimensions}}
            wrapWithQueryRenderer={false}
            onVizStateChanged={(vizState) => {
                setQueryParam({query: vizState.query});
            }}
            render={({
                query,
                segments,
                updateSegments,
                timeDimensions,
                updateTimeDimensions,
                missingMembers,
                updateQuery,
                isFetchingMeta,
                availableMembers,
                dryRunError,
                filters,
                updateFilters,
                availableFilterMembers,
            }) => {
                availableFilterMembers = filterMembers(GASchema, 'journey', department, userName, availableFilterMembers, filters, metaExtended, query, 'filter')
                availableMembers.timeDimensions = filterMembers(GASchema, 'journey', department, userName, availableMembers.timeDimensions, timeDimensions, metaExtended, query, 'time')
                availableMembers.segments = filterMembers(GASchema, 'retention', department, userName, availableMembers.segments, segments, metaExtended, query, 'segment')
                // console.log(query)
                return (
                    <Fragment>
                    <Drawer title={selectedNode} placement="right" onClose={onClose} open={drawerOpen} mask={false} width='50vw'>
                    {selectedNode === 'First Visit' ? <Container style={{background: '#fff'}}>
                        
                        <Alert 
                            message="Users are anonymous at this stage, we only track them thanks to their cookie set on the device. Therefore it is possible that real persons appear multiple times under different cookie IDs." 
                        type="info" showIcon/>
                        <Col span={24} style={{marginTop: '8px'}}>
                            1. How are readers reaching SCMP.com? (traffic source) <b>[EASY]</b>
                        </Col>
                        <Col span={24}>
                            2. How many articles in avg are they consuming (apv/100% completions total and by topic)? And how long are they staying on average? <b>[EASY]</b>
                        </Col>
                        <Col span={24}>
                            3. How many of them do come back? (hint for developer: using fact_daily_known_users 90 days prior to registration). How many of them register in that session? <b>[HARD]</b>
                        </Col>
                    </Container>
                    : selectedNode === 'Registration' ? <Container style={{background: '#fff'}}>
                       
                        <Col span={24}>
                            1. How long between first visit and registration? (median, min, max)<b>[EASY]</b>
                        </Col>
                        <Col span={24}>
                            2. How many articles in avg have they consume before registering? How many of them did complete the onboarding? What NL do they subscribe to during onboarding? <b>[HARD]</b>
                        </Col>
                        <Col span={24}>
                            3. Look at SCMP triggers (NL, push, social), how much of it is being consumed in avg. Compare with direct visits. <b>[MEDIUM]</b>
                        </Col>
                    </Container>
                    : selectedNode === 'Subscription' ? <Container style={{background: '#fff'}}>
                        
                        <Col span={24}>
                            1. How long between first visit and subscription, and how long between registration and subscription? <b>[EASY]</b>
                        </Col>
                        <Col span={24}>
                            2. Last touch session: which article was their last? what was the entrypoint? what is their loyalty at subscription? <b>[EASY]</b>
                        </Col>
                        <Col span={24}>
                            3. Some stats: the split per product/plan, how many of them are winback? <b>[EASY]</b>
                        </Col>
                    </Container>
                    : selectedNode === 'Engaged' ? <Container style={{background: '#fff'}}>
                        
                        <Col span={24}>
                            1. What is their loyalty? <b>[EASY]</b>
                        </Col>
                        <Col span={24}>
                            2. Look at SCMP triggers (NL, push), how much of it is being consumed in avg. Compare with direct visits.
                            How many triggers on avg daily (NL & app)  <b>[MEDIUM]</b>
                        </Col>
                        <Col span={24}>
                            3. Any actions detected helping forming habit? <b>[HARD]</b>
                        </Col>
                    </Container>
                    : selectedNode === 'Disengaged' ? <Container style={{background: '#fff'}}>
                        
                        <Col span={24}>
                            1. When did their loyalty drop? How long after registering or subscribing? <b>[MEDIUM]</b>
                        </Col>
                        <Col span={24}>
                            2. What is their loyalty? What is their weekly retention? <b>[HARD]</b>
                        </Col>
                        <Col span={24}>
                            3. Look at SCMP triggers (NL, push, social), how much of it is being consumed in avg. Compare with direct visits. <b>[MEDIUM]</b>
                        </Col>
                    </Container>
                    : selectedNode === 'Churn' ? <Container style={{background: '#fff'}}>
                        <Col span={24}>
                            1. What is their loyalty? <b>[EASY]</b>
                        </Col>
                        <Col span={24}>
                            2. Look at SCMP triggers (NL, push, social), how much of it is being consumed in avg. Compare with direct visits. <b>[MEDIUM]</b>
                        </Col>
                        <Col span={24}>
                            3. Comparison of engagement levels before and after churning <b>[HARD]</b>
                        </Col>
                    </Container>
        : <></>}
                    </Drawer>
                    <Container style={{ borderRadius: '8px', marginBottom: '16px'}}>
                        <Row justify="space-between" align="middle">
                            <Alert 
                                message={`This dashboard is work in progress and data is static... Only CAD can access for now`} 
                                type="info" 
                                showIcon
                            />
                            
                            <Typography style={{marginRight: '4px', fontStyle: 'italic'}}>{`Data last refreshed on: ${snapshotDate.format('YYYY-MM-DD')}`}</Typography>
                                {/* <DatePicker 
                                    disabledDate={disabledDate}
                                    defaultValue={snapshotDate}
                                    allowClear={false}
                                    disabled
                                /> */}
                        </Row>
                    </Container>
                        <Container className="toBeClosed" style={{background: '#fff', padding: '16px', borderRadius: '8px', marginBottom: '16px'}} 
                                   onClick={(e) => {
                                        if (drawerOpen && e.target.className.includes('toBeClosed')) {
                                            setDrawerOpen(false)
                                            setSelectedNode(null)
                                        }
                                    }}
                        >
                            <Row className="toBeClosed" align="top" gutter={0}>

                                <Section>
                                <SectionHeader>Time</SectionHeader>
                                <TimeGroup
                                    mode="journey"
                                    disabled={isFetchingMeta}
                                    members={timeDimensions}
                                    availableMembers={
                                    availableMembers?.timeDimensions || []
                                    }
                                    missingMembers={missingMembers}
                                    addMemberName="Time"
                                    updateMethods={playgroundActionUpdateMethods(
                                    updateTimeDimensions,
                                    'Time'
                                    )}
                                />
                                </Section>

                                <Section>
                                <SectionHeader>Audiences</SectionHeader>
                                <MemberGroup
                                    disabled={isFetchingMeta}
                                    members={segments}
                                    availableMembers={availableMembers?.segments || []}
                                    missingMembers={missingMembers}
                                    addMemberName="Audience"
                                    updateMethods={playgroundActionUpdateMethods(
                                    updateSegments,
                                    'Segment'
                                    )}
                                />
                                </Section>

                                <Section>
                                <SectionHeader>Filters</SectionHeader>
                                <FilterGroup
                                    key='queryFilter'
                                    disabled={isFetchingMeta}
                                    members={filters}
                                    availableMembers={availableFilterMembers}
                                    missingMembers={missingMembers}
                                    addMemberName="Filter"
                                    updateMethods={playgroundActionUpdateMethods(
                                    updateFilters,
                                    'Filter'
                                    )}
                                />
                                </Section>
                                
                            </Row>
                            <Row className="toBeClosed" style={{ paddingTop: '24px' }} justify='center'>                      
                                <Button
                                    icon={<PlaySquareOutlined />}
                                    type="primary"
                                    className="main-button"
                                    loading={renderProps.isLoading}
                                    disabled={isFetchingMeta || dryRunError}
                                    onClick={async () => {
                                        await decodeCheckExpiredToken();
                                        handleRunButtonClick({query});
                                    }}
                                >
                                    Run query
                                </Button>
                            </Row>
                            <CustomerJourneyGraph setSelectedNode={setSelectedNode} selectedNode={selectedNode} setDrawerOpen={setDrawerOpen} drawerOpen={drawerOpen} />
                        </Container>
        </Fragment>
        )
            }}
        />
    </QueryContext>
    </ReactFlowProvider>
  );
}