
import { Segmented } from 'antd';
import {
  AreaChartOutlined,
  TableOutlined,
  DatabaseOutlined,
  JavaScriptOutlined
} from '@ant-design/icons';


const SelectChartType = ({ chartType, chartOption, updateChartOption }) => {

  return (
    <Segmented
      options={[
        {
          label: 'Chart',
          value: 'chart',
          icon: <AreaChartOutlined />,
          disabled: chartType === 'table' || chartType === 'number'
        },
        {
          label: 'Table',
          value: 'table',
          icon: <TableOutlined />,
        },
        {
          label: 'JSON',
          value: 'query',
          icon: <JavaScriptOutlined />
        },
        {
          label: 'SQL',
          value: 'sql',
          icon: <DatabaseOutlined />,
        }
      ]}
      value={chartOption}
      onChange={updateChartOption}
    />
  );
};

export default SelectChartType;